<template>
  <div class="d-flex align-items-center">
    <base-button @click="$router.back()" :back="true">
      <horizontal-arrow />
    </base-button>
    <h1 class="m-0 ms-3">
      {{ information.first_name }} {{ information.last_name }}
    </h1>
  </div>
  <div class="row align-items-center employeesSinglePage__header">
    <div class="col-lg-6">
      <div class="employeesSinglePage__userInfo">
        <avatar
          user-role="employees"
          size="100"
          :image-name="information.image"
          :user-code="information.code"
          height="100px"
          width="100px"
        />
        <div class="ml-20">
          <div class="employeesSinglePage__name">
            {{ information.first_name }} {{ information.last_name }}
          </div>
          <div class="employeesSinglePage__code">{{ information.code }}</div>
          <div class="employeesSinglePage__code opacity-50">
            {{ $t("filterByLastSeen") }}:
            <data-last-seen :data="information.last_seen" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="employeesSinglePage__companyInformation">
        <div class="employeesSinglePage__companyInformation-logo">
          <company-logo
            v-if="information.company"
            :company_slug="information.company.code"
            :image-name="information.company.logo"
            size="300"
          />
        </div>
        <div class="employeesSinglePage__companyInformation-id">
          <div class="employeesSinglePage__name">
            {{ information.company.name }}
          </div>
          <div class="employeesSinglePage__code">
            {{ information.company.code }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="employeesSinglePage">
    <div class="row">
      <h5 class="employeesSinglePage__title">
        {{ $t("employeeInformation") }}
      </h5>
      <div class="col-12 col-lg-6 pr-25">
        <base-input
          class="w-100 expertAddPage__input"
          v-model="information.first_name"
          type="text"
          :error="v$.information.first_name.$error"
          :error-message="v$.information.first_name.$errors"
        >
          {{ $t("accountOverviewPageName") }}
        </base-input>
        <base-input
          class="w-100 expertAddPage__input"
          v-model="information.last_name"
          type="text"
          :error="v$.information.last_name.$error"
          :error-message="v$.information.last_name.$errors"
        >
          {{ $t("accountOverviewPageSurname") }}
        </base-input>
        <div class="expertAddPage__input">
          <span
            :class="{ 'baseInput__area-focus': information.position }"
            class="baseInput__label"
          >
            {{ $t("accountOverviewPagePosition") }}*
          </span>
          <vue-multiselect
            class="accountOverviewMultiselect"
            v-if="information.position"
            :options="positionOptions"
            v-model="information.position"
            :show-labels="false"
            placeholder=""
            openDirection="bottom"
            label="name"
            track-by="name"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6 pl-25">
        <div class="d-flex justify-content-between">
          <div class="expertAddPage__input accountPage__input-small">
            <span
              :class="{
                'baseInput__area-focus expertAddPage__input-label':
                  information.gender,
              }"
              class="baseInput__label"
            >
              {{ $t("accountOverviewPageGender") }}
            </span>
            <vue-multiselect
              class="accountOverviewMultiselect"
              :options="genderOptions"
              v-model="information.gender"
              placeholder=""
              :show-labels="false"
              openDirection="bottom"
              label="name"
              track-by="name"
            />
          </div>
          <div
            class="expertAddPage__input position-relative accountPage__input-small mb-0"
          >
            <span
              class="baseInput__label"
              :class="{ 'baseInput__area-focus': information.birthdate }"
            >
              {{ $t("accountOverviewPageBirth") }}</span
            >
            <datepicker
              inputClassName="registrationPage__datePicker"
              class=""
              v-model="information.birthdate"
              format="dd/MM/yyyy"
              :flow="flow"
              :enableTimePicker="false"
              hideInputIcon
              autoApply
            />
          </div>
        </div>
        <base-input
          class="w-100 expertAddPage__input"
          v-model="information.email"
          type="text"
          :error="v$.information.email.$error"
          :error-message="v$.information.email.$errors"
        >
          {{ $t("accountOverviewPageEmail") }}
        </base-input>
        <div class="expertAddPage__input expertAddPage-edit__input-language">
          <span
            :class="{ 'baseInput__area-focus': information.language }"
            class="baseInput__label"
          >
            {{ $t("accountOverviewPageLanguage") }}*
          </span>
          <vue-multiselect
            class="accountOverviewMultiselect"
            :options="languageOptions"
            v-model="information.language"
            placeholder=""
            :show-labels="false"
            openDirection="bottom"
            label="name"
            track-by="name"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <h5 class="employeesSinglePage__title">{{ $t("contactInformation") }}</h5>
      <div class="col-12 col-lg-6">
        <base-input
          class="w-100 expertAddPage__input"
          v-model="information.country"
        >
          {{ $t("country") }}
        </base-input>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              class="w-100 expertAddPage__input"
              v-model="information.city"
            >
              {{ $t("city") }}
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              class="w-100 expertAddPage__input"
              v-model="information.postal_code"
            >
              {{ $t("postalCode") }}
            </base-input>
          </div>
        </div>
        <base-input class="w-100" v-model="information.address" type="text">
          {{ $t("accountOverviewPageAddress") }}
        </base-input>
      </div>
      <div class="col-12 col-lg-6">
        <base-input
          class="w-100 expertAddPage__input"
          v-model="information.office_number"
          type="text"
        >
          {{ $t("accountOverviewPageOffice") }}
        </base-input>
        <base-input
          class="w-100 expertAddPage__input"
          v-model="information.mobile_number"
          type="text"
        >
          {{ $t("accountOverviewPageMobile") }}
        </base-input>
        <base-input
          class="w-100 expertAddPage__input mb-0"
          v-model="information.phone_number"
          type="text"
        >
          {{ $t("accountOverviewPagePhone") }}
        </base-input>
      </div>
    </div>
    <div class="expertAddPage__footer">
      <div class="expertAddPage__buttons">
        <div
          v-show="!haveChanges"
          @click="getEmployeeInformation()"
          class="accountPage__cancel"
        >
          {{ $t("discardChanges") }}
        </div>
        <base-button
          :is-loaded="imageIsLoaded"
          :book="true"
          @click="editEmployee()"
          >{{ $t("accountOverviewPageSave") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import BaseButton from "@/components/UI/buttons/BaseButton";
import Avatar from "@/components/UI/images/Avatar";
import BaseInput from "@/components/UI/inputs/BaseInput";
import VueMultiselect from "vue-multiselect";
import { format } from "date-fns";
import SaveChangesPopup from "@/components/elements/popups/saveChangesPopup";
import DataLastSeen from "@/components/components-helpers/DataLastSeen";
import CompanyLogo from "@/components/UI/images/CompanyLogo";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import { ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";

export default {
  name: "EmployeesAdminSinglePage",
  components: {
    CompanyLogo,
    DataLastSeen,
    SaveChangesPopup,
    BaseInput,
    Avatar,
    BaseButton,
    HorizontalArrow,
    MainLayout,
    VueMultiselect,
    Datepicker,
  },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();
    const flow = ref(["year", "month", "calendar"]);

    return {
      flow,
      toast,
      v$,
    };
  },
  data() {
    return {
      position: [],
      haveChanges: false,
      information: {
        company: { code: null },
        user: { language: null },
      },
      informationBackups: {},
      genderOptions: [],
      positionOptions: [],
      languageOptions: [],
    };
  },
  validations() {
    return {
      information: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
      },
    };
  },
  mounted() {
    this.getOptions();
    this.$store.commit("SET_LOADED_STATE", false);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.haveChanges) {
      let changesPopup = {
        show: true,
        to: to.path,
        save: null,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    } else {
      return next();
    }
  },
  methods: {
    getOptions() {
      this.$http.get("/genders", {}).then(({ data }) => {
        this.genderOptions = data.body;
      });
      this.$http.get("/employees-positions", {}).then(({ data }) => {
        data.body.forEach((element) => {
          this.positionOptions.push({
            name: element.translations[0].name,
            id: element.id,
          });
        });
      });
      this.$http.get("/platform-languages", {}).then(({ data }) => {
        this.languageOptions = data.body;
        this.getEmployeeInformation();
      });
    },
    async getEmployeeInformation() {
      let employeeByCode = await this.$http
        .get(`/employees/code/${this.$route.params.employee}`)
        .then(({ data }) => {
          return data.body;
        });
      let employeeInformation = await this.$http
        .get(`/employees/${employeeByCode.user_id}`)
        .then(({ data }) => {
          return data.body;
        });
      let info = {
        is_blocked: employeeInformation.user.is_blocked,
        user_id: employeeInformation.user_id,
        profile_id: employeeInformation.user.profile_id,
        role_id: 4,
        last_name: employeeInformation.last_name,
        first_name: employeeInformation.first_name,
        email: employeeInformation.user.email,
      };
      this.$store.commit("SET_EDIT_USER_INFORMATION", info);
      employeeInformation.position = this.positionOptions.filter(
        (element) => element.id === employeeByCode.position_id
      );
      employeeInformation.language = this.languageOptions.filter(
        (element) => element.id === employeeByCode.language_id
      )[0];
      employeeInformation.birthdate = format(
        new Date(employeeInformation.birthdate),
        "yyyy-MM-dd"
      );
      employeeInformation.email = employeeInformation.user.email;
      this.informationBackups = JSON.parse(JSON.stringify(employeeInformation));
      this.information = { ...employeeInformation };
    },
    editEmployee() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      this.$store.commit("SET_LOADED_STATE", true);
      let birthday =
        format(new Date(this.information.birthdate), "yyyy-MM-dd") +
        "T00:00:00.000Z";
      this.$http
        .patch("/employees/" + this.information.user_id, {
          employee: {
            gender_id: this.information.gender
              ? this.information.gender.id
              : null,
            position_id: this.information.position
              ? this.information.position.id
              : null,
            first_name: this.information.first_name,
            last_name: this.information.last_name,
            country: this.information.country,
            city: this.information.city,
            postal_code: this.information.postal_code,
            address: this.information.address,
            mobile_number: this.information.mobile_number,
            phone_number: this.information.phone_number,
            office_number: this.information.office_number,
            birthdate: birthday,
          },
          profile: {
            language_id: this.information.language
              ? this.information.language.id
              : null,
          },
          user: {
            email: this.information.email,
          },
        })
        .then(() => {
          this.toast.success(this.$t("successfulSaveData"));
          this.$store.commit("SET_LOADED_STATE", false);
          this.$store.commit("SET_ACCOUNT_CHANGES", true);
          this.$router.push("/employees");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(err.response.data.message);
          } else {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      imageIsLoaded: "LOADED_STATE",
      popupsInformation: "STATE_POPUP",
    }),
  },
  watch: {
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.editEmployee();
          this.haveChanges = true;
          this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
          this.$router.push(newCount.to);
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
          this.$router.push(newCount.to);
        }
      },
    },
    information: {
      deep: true,
      handler(newCount) {
        this.haveChanges =
          JSON.stringify(this.informationBackups) === JSON.stringify(newCount);
        this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
      },
    },
  },
};
</script>

<style></style>
