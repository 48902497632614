<template>
  <div class="row employeeAppointmentHistoryTable">
    <div class="col-lg-6">
      <div class="employeeAppointmentHistoryTable__topText">
        Psychological support
      </div>
      <div class="employeeAppointmentHistoryTable__bottomText">
        First consultation
      </div>
    </div>
    <div class="col-lg-4">
      <div class="employeeAppointmentHistoryTable__topText">13:30</div>
      <div class="employeeAppointmentHistoryTable__bottomText">15 May 2022</div>
    </div>
    <div class="col-lg-2">
      <div class="employeeAppointmentHistoryTable__coast">€20</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmployeeAppointmentHistoryTable",
};
</script>

<style></style>
