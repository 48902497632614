<template>
  <main-layout>
    <employees-admin-single-page v-if="loginUser.role_id === 1" />
    <employees-company-single-page v-if="loginUser.role_id === 3" />
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import { mapGetters } from "vuex";
import EmployeesAdminSinglePage from "@/components/pages-templates/employee/EmployeesAdminSinglePage";
import EmployeesCompanySinglePage from "@/components/pages-templates/employee/EmployeesCompanySinglePage";

export default {
  name: "EmployeesSinglePage",
  components: {
    EmployeesCompanySinglePage,
    EmployeesAdminSinglePage,
    MainLayout,
  },
  beforeRouteLeave(to, from, next) {
    if (this.loginUser.role_id === 3) return next();
    if (!this.haveChanges) {
      let changesPopup = {
        show: true,
        to: to.path,
        save: null,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    } else {
      return next();
    }
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
      haveChanges: "ACCOUNT_CHANGES",
    }),
  },
};
</script>

<style></style>
