<template>
  <div class="d-flex align-items-center mb-30">
    <base-button @click="$router.back()" :back="true">
      <horizontal-arrow />
    </base-button>
    <h1 class="m-0 ms-3">{{ $t("accountOverviewPageOverview") }}</h1>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="employeesCompanySinglePage__infoWrapper">
        <div class="d-flex align-items-center">
          <avatar
            user-role="employees"
            size="100"
            :image-name="employeeInformation.image"
            :user-code="employeeInformation.code"
            width="100px"
            height="100px"
          />
          <div class="ml-20">
            <div class="appointmentOverviewPage__name">
              {{
                `${employeeInformation.first_name} ${employeeInformation.last_name}`
              }}k
            </div>
            <div class="appointmentOverviewPage__code">
              {{ employeeInformation.code }}
            </div>
          </div>
        </div>
        <div class="row mt-30">
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("accountOverviewPageName") }}
            </div>
            <div class="appointmentOverviewPage__text">
              {{ employeeInformation.first_name }}
            </div>
          </div>
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("accountOverviewPagePosition") }}
            </div>
            <div
              v-if="employeeInformation.position"
              class="appointmentOverviewPage__text"
            >
              {{ employeeInformation.position.translations[0].name }}
            </div>
          </div>
        </div>
        <div class="row mt-30">
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("accountOverviewPageSurname") }}
            </div>
            <div class="appointmentOverviewPage__text">
              {{ employeeInformation.last_name }}
            </div>
          </div>
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("accountOverviewPageGender") }}
            </div>
            <div
              v-if="employeeInformation.gender"
              class="appointmentOverviewPage__text"
            >
              {{ employeeInformation.gender.name }}
            </div>
          </div>
        </div>
        <div class="row mt-30 appointmentOverviewPage__rowWithStroke">
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("accountOverviewPageEmail") }}
            </div>
            <div class="appointmentOverviewPage__text">
              {{ employeeInformation.email }}
            </div>
          </div>
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("accountOverviewPageBirth") }}
            </div>
            <div class="appointmentOverviewPage__text">
              <data-formatted
                date-format="dd/MM/yyyy"
                :data="employeeInformation.birthdate"
              />
            </div>
          </div>
        </div>
        <div class="row mt-30">
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("country") }}
            </div>
            <div class="appointmentOverviewPage__text">
              {{ employeeInformation.country }}
            </div>
          </div>
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("accountOverviewPageOffice") }}
            </div>
            <div class="appointmentOverviewPage__text">
              {{ employeeInformation.office_number }}
            </div>
          </div>
        </div>
        <div class="row mt-30">
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">{{ $t("city") }}</div>
            <div class="appointmentOverviewPage__text">
              {{ employeeInformation.city }}
            </div>
          </div>
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("accountOverviewPageMobile") }}
            </div>
            <div class="appointmentOverviewPage__text">
              {{ employeeInformation.mobile_number }}
            </div>
          </div>
        </div>
        <div class="row mt-30">
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("postalCode") }}
            </div>
            <div class="appointmentOverviewPage__text">
              {{ employeeInformation.postal_code }}
            </div>
          </div>
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("accountOverviewPagePhone") }}
            </div>
            <div class="appointmentOverviewPage__text">
              {{ employeeInformation.phone_number }}
            </div>
          </div>
        </div>
        <div class="row mt-30">
          <div class="col-lg-6">
            <div class="appointmentOverviewPage__title">
              {{ $t("accountOverviewPageAddress") }}
            </div>
            <div class="appointmentOverviewPage__text">
              {{ employeeInformation.address }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <h5>{{ $t("employeeHomePageRecent") }}</h5>
      <div class="servicesPage__filter">{{ $t("filterBy") }}</div>
      <div class="servicesPage__filterWrapper">
        <base-button
          @click="filterBy('currentMonth')"
          :active="filter.currentMonth"
          :sort="true"
        >
          {{ $t("currentMonth") }}
        </base-button>
        <datepicker
          :locale="loginUser.language_code"
          :class="{ 'datepickerInputFilterClass-active': filter.customData }"
          :enableTimePicker="false"
          inputClassName="datepickerInputFilterClass"
          class="datepickerFilterClass"
          v-model="statisticDataRange"
          @closed="
            setCustomDateRange();
            filterBy('customData');
          "
          format="dd/MM"
          :placeholder="$t('custom')"
          autoApply
          range
          utc
        />
        <div class="col-2 experts__filterReset" @click="resetFilter()">
          <reset-icon />
          {{ $t("resetFilter") }}
        </div>
      </div>
      <div class="employeesCompanySinglePage__scrollable">
        <div
          class="employeeTransactions__statistics employeeTransactions__statistics-gradient mb-10"
        >
          <h5>
            <data-formatted
              v-if="thisMonth"
              date-format="dd"
              :data="statisticData.start"
            />
            <data-formatted
              v-else
              date-format="dd MMM"
              :data="statisticData.start"
            />
            -
            <data-formatted
              date-format="dd MMM, yyyy:"
              :data="statisticData.end"
            />
          </h5>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              5<small>{{ $t("appointments") }}</small>
            </div>
            <div>
              €327 <small>{{ $t("spent") }}</small>
            </div>
          </div>
        </div>
        <employee-appointment-history-table />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import Avatar from "@/components/UI/images/Avatar";
import VueMultiselect from "vue-multiselect";
import Datepicker from "@vuepic/vue-datepicker";
import { isSameMonth, startOfMonth } from "date-fns";
import { mapGetters } from "vuex";
import ResetIcon from "@/components/UI/icons/filterIcons/ResetIcon";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import EmployeeAppointmentHistoryTable from "@/components/elements/employees/EmployeeAppointmentHistoryTable";

export default {
  name: "EmployeesCompanySinglePage",
  components: {
    EmployeeAppointmentHistoryTable,
    DataFormatted,
    ResetIcon,
    Avatar,
    HorizontalArrow,
    BaseButton,
    VueMultiselect,
    Datepicker,
  },
  data() {
    return {
      filter: {
        currentMonth: true,
        customData: false,
      },
      employeeInformation: {},
      statisticDataRange: [],
      statisticData: { start: startOfMonth(new Date()), end: new Date() },
    };
  },
  mounted() {
    this.getEmployeeInformation();
  },
  methods: {
    filterBy(key) {
      for (let i in this.filter) {
        this.filter[i] = key === i;
      }
      if (key === "currentMonth") {
        this.setCurrentMonth();
      }
    },
    resetFilter() {},
    async setCurrentMonth() {
      this.statisticData.start = startOfMonth(new Date());
      this.statisticData.end = new Date();
      this.statisticDataRange = [];
      this.statistics = await this.getStatistics(
        this.filterByServices,
        this.statisticData.start,
        this.statisticData.end
      );
    },
    async setCustomDateRange() {
      this.statisticData.start = new Date(this.statisticDataRange[0]);
      this.statisticData.end = new Date(this.statisticDataRange[1]);
      this.statistics = await this.getStatistics(
        this.filterByServices,
        this.statisticData.start,
        this.statisticData.end
      );
    },
    getEmployeeInformation() {
      this.$http
        .get(`employees/code/${this.$route.params.employee}`)
        .then(({ data }) => {
          this.employeeInformation = data.body;
        });
    },
    async getStatistics(serviceFilter, dataStart, dataEnd) {
      // let service = serviceFilter ? serviceFilter : null;
      // let start = dataStart ? dataStart : null;
      // let end = dataEnd ? dataEnd : null;
      // return this.$http.get(`statistics/employee/transactions`, {
      //   params: {
      //     service: service,
      //     start: start,
      //     end: end,
      //   }
      // }).then(({data}) => {
      //   return data.body;
      // });
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
    thisMonth() {
      return isSameMonth(
        new Date(this.statisticData.start),
        new Date(this.statisticData.end)
      );
    },
  },
};
</script>

<style></style>
